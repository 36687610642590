import React from 'react'

const SvgShareButton = props => (
  <svg width={50} height={50} fill="none" {...props}>
    <circle cx={25} cy={25} r={25} fill="#fff" />
    <path
      d="M12.835 25.003c0 .454-.12.878-.321 1.24a2.516 2.516 0 01-2.19 1.28 2.517 2.517 0 01-2.511-2.52 2.517 2.517 0 012.51-2.52c.935 0 1.759.514 2.18 1.28.212.363.332.787.332 1.24zM33.426 9.375a2.516 2.516 0 00-2.511 2.52 2.516 2.516 0 002.511 2.52 2.515 2.515 0 002.511-2.52 2.515 2.515 0 00-2.51-2.52zm0 26.21a2.516 2.516 0 00-2.511 2.52 2.516 2.516 0 002.511 2.52 2.515 2.515 0 002.511-2.52 2.515 2.515 0 00-2.51-2.52zm-1.768 1.518l-19.57-11.1 19.57 11.1zm-19.57-13.106l19.57-11.1-19.57 11.1z"
      fill="#9C997C"
    />
    <path
      d="M12.088 23.997l19.57-11.1M12.835 25.002c0 .454-.12.878-.321 1.24a2.516 2.516 0 01-2.19 1.28 2.517 2.517 0 01-2.511-2.52 2.517 2.517 0 012.51-2.52c.935 0 1.759.514 2.18 1.28.212.363.332.787.332 1.24zM33.426 9.375a2.516 2.516 0 00-2.511 2.52 2.516 2.516 0 002.511 2.52 2.515 2.515 0 002.511-2.52 2.515 2.515 0 00-2.51-2.52zm0 26.21a2.516 2.516 0 00-2.511 2.52 2.516 2.516 0 002.511 2.52 2.515 2.515 0 002.511-2.52 2.515 2.515 0 00-2.51-2.52zm-1.768 1.518l-19.57-11.1 19.57 11.1z"
      stroke="#9C997C"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
  </svg>
)

export default SvgShareButton
