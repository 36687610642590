import React from 'react'

const SvgShareLarge = props => (
  <svg width={80} height={80} fill="none" {...props}>
    <circle cx={40} cy={40} r={40} fill="#9C997C" />
    <path
      d="M20.537 40.006c0 .725-.193 1.403-.515 1.984a4.025 4.025 0 01-3.504 2.048 4.027 4.027 0 01-4.018-4.032c0-2.226 1.8-4.033 4.018-4.033a3.98 3.98 0 013.488 2.049c.338.58.53 1.258.53 1.984zM53.482 15a4.025 4.025 0 00-4.019 4.032 4.025 4.025 0 004.019 4.033 4.025 4.025 0 004.018-4.033A4.025 4.025 0 0053.482 15zm0 41.935a4.025 4.025 0 00-4.019 4.033A4.025 4.025 0 0053.482 65a4.025 4.025 0 004.018-4.032 4.025 4.025 0 00-4.018-4.033zm-2.83 2.43l-31.311-17.76 31.312 17.76zm-31.311-20.97l31.312-17.76-31.312 17.76z"
      fill="#fff"
    />
    <path
      d="M19.34 38.396l31.313-17.762M20.537 40.006c0 .725-.193 1.403-.515 1.984a4.025 4.025 0 01-3.504 2.048 4.027 4.027 0 01-4.018-4.032c0-2.226 1.8-4.033 4.018-4.033a3.98 3.98 0 013.488 2.049c.338.58.53 1.258.53 1.984zM53.482 15a4.025 4.025 0 00-4.019 4.032 4.025 4.025 0 004.019 4.033 4.025 4.025 0 004.018-4.033A4.025 4.025 0 0053.482 15zm0 41.935a4.025 4.025 0 00-4.019 4.033A4.025 4.025 0 0053.482 65a4.025 4.025 0 004.018-4.032 4.025 4.025 0 00-4.018-4.033zm-2.83 2.43l-31.311-17.76 31.312 17.76z"
      stroke="#fff"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
  </svg>
)

export default SvgShareLarge
