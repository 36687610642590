import React from 'react'
import PropTypes from 'prop-types'

const SvgBackButton = ({
  width = 50,
  background = '#9c997c',
  fill = '#fff',
  ...props
}) => (
  <svg width={width} fill="none" viewBox="0 0 50 50" {...props}>
    <circle
      cx={25}
      cy={25}
      r={25}
      transform="rotate(-180 25 25)"
      fill={background}
    />
    <path
      d="M13.985 23.26a1.5 1.5 0 00-.069 2.121l9.233 9.849a1.5 1.5 0 102.189-2.052l-8.207-8.754 8.754-8.208a1.5 1.5 0 00-2.052-2.188l-9.848 9.233zm21.063.24l-19.99-.644-.096 2.998 19.99.645.096-2.998z"
      fill={fill}
    />
  </svg>
)
SvgBackButton.propTypes = {
  background: PropTypes.string,
  fill: PropTypes.string,
}

export default SvgBackButton
