import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import ShareButton from '../icons/ShareButton'
import ShareButtonLarge from '../icons/ShareLarge'
import Facebook from '../icons/Facebook'
import FacebookLarge from '../icons/FacebookLarge'
import Mail from '../icons/Mail'
import MailLarge from '../icons/MailLarge'
// todo: these are SVGs -- I don't need separate files!

const Container = styled.div`
  /* ${props =>
    props.isMobile
      ? `
    transform: scale(${props.large ? 0.7 : 0.8});
  `
      : ''} */
  transition: 300ms;
  width: 100%;
  ${({ large, isExpanded }) => {
    if (large) {
      return isExpanded
        ? `
          height: 288px
          `
        : `
          height: 112px
          `
    }
    return isExpanded ? 'height: 180px' : 'height: 70px'
  }};
  background: ${props => (props.isExpanded ? '#9c997b;' : 'transparent')};
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  .share-button {
    position: absolute;
    top: 10px;
    left: 5px;
  }
  .share-button-large {
    position: absolute;
    top: 16px;
    left: 8px;
  }
  .facebook {
    position: absolute;
    top: 80px;
    left: 10px;
  }
  .facebook-large {
    position: absolute;
    top: 128px;
    left: 16px;
  }
  .mail {
    position: absolute;
    top: 130px;
    left: 10px;
  }
  .mail-large {
    position: absolute;
    top: 208px;
    left: 16px;
  }
`

const Share = ({ slug, large }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMedia({ maxWidth: 650 })

  const handleOpen = useCallback(e => {
    e.stopPropagation()
    setIsExpanded(true)
  }, [])
  const handleClose = useCallback(() => setIsExpanded(false), [])

  return (
    <Container
      isExpanded={isExpanded}
      onClick={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      large={large}
      isMobile={isMobile}
    >
      {large ? (
        <ShareButtonLarge
          isExpanded={isExpanded}
          className="share-button-large"
        />
      ) : (
        <ShareButton className="share-button" />
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdoulasanja.com%2Fblog%2F${slug}&amp;src=sdkpreparse`}
      >
        {large ? (
          <FacebookLarge className="facebook-large" />
        ) : (
          <Facebook className="facebook" />
        )}
      </a>
      <a href={`mailto:friend?body=https://doulasanja.com/blog/${slug}`}>
        {large ? (
          <MailLarge className="mail-large" />
        ) : (
          <Mail className="mail" />
        )}
      </a>
    </Container>
  )
}

export default Share
