import React from 'react'

const SvgFacebookLarge = ({ fill = '#fff', width = 64, ...props }) => (
  <svg width={width} fill="none" {...props} viewBox="0 0 64 64">
    <path
      d="M64 32.216c0 15.322-10.633 28.12-24.875 31.384V36.838h7.692v-8.936h-7.768v-1.995c0-3.83 1.665-6.942 5.627-6.942 1.587 0 2.856.24 3.888.558l.475-9.336c-1.667-.478-3.721-.799-6.42-.799-3.486 0-7.69 1.042-10.698 3.913-3.49 3.193-4.916 8.38-4.916 13.086v1.515H21.93v8.936h5.072V64C11.71 61.58 0 48.292 0 32.216 0 14.424 14.328 0 32.002 0 49.674-.002 64 14.423 64 32.216z"
      fill={fill}
    />
  </svg>
)

export default SvgFacebookLarge
