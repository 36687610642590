import React from "react"

const SvgFacebook = ({ width = 40, ...props }) => (
  <svg width={width} fill="none" {...props} viewBox="0 0 40 40">
    <path
      d="M40 20.135c0 9.576-6.646 17.575-15.547 19.615V23.024h4.808v-5.585h-4.855v-1.247c0-2.394 1.04-4.339 3.516-4.339.992 0 1.785.15 2.43.349l.297-5.835c-1.041-.3-2.325-.5-4.012-.5-2.179 0-4.806.652-6.686 2.446-2.181 1.996-3.073 5.237-3.073 8.179v.947h-3.172v5.585h3.17V40C7.318 38.487 0 30.182 0 20.135 0 9.015 8.955 0 20 0c11.047-.001 20 9.014 20 20.135z"
      fill="#fff"
    />
  </svg>
)

export default SvgFacebook
