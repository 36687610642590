import React from 'react'

const SvgMailLarge = ({ fill = '#fff', width = 64, ...props }) => (
  <svg width={width} fill="none" {...props} viewBox="0 0 64 64">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32zM16.509 16.572H47.49c2.659 0 4.822 2.077 4.822 4.63v21.596c0 2.553-2.163 4.63-4.822 4.63H16.509c-2.66 0-4.822-2.077-4.822-4.63V21.202c0-2.553 2.163-4.63 4.822-4.63zm0 26.768H47.49c.39 0 .733-.253.734-.542v-18.74l-14.013 11.83a1.599 1.599 0 01-1.03.377h-2.366c-.376 0-.741-.134-1.028-.377l-14.013-11.83v18.74c0 .289.343.542.734.542zm1.862-22.68L32 32.205 45.629 20.66H18.37z"
      fill={fill}
    />
  </svg>
)

export default SvgMailLarge
