import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import useResizeAware from 'react-resize-aware'
import BlockContent from '@sanity/block-content-to-react'
import { navigate } from '@reach/router'
import useMedia from 'use-media'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors } from '../components/theme'
import BackButton from '../icons/BackButton'
import Share from '../components/Share'

const Container = styled.article`
  padding: 220px 60px 150px;
  max-width: 1160px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 850px) {
    padding: 220px 40px 60px;
  }
  @media (max-width: 650px) {
    padding: 170px 30px 30px;
  }
`

const BackButtonContainer = styled.button`
  border: none;
  position: absolute;
  cursor: pointer;
  left: ${props => props.padding}px;
  top: 150px;
  padding: 0;
  background: transparent;
  @media (max-width: 650px) {
    top: 120px;
  }
`

const Header = styled.div`
  width: 100%;
  height: 500px;
  position: relative;

  div {
    height: 90px;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
  }

  h1 {
    font-weight: 500;
    font-size: 1.1rem;
    color: #9c997b;
    margin: 0;
    margin-bottom: 0.4rem;
  }
  h2 {
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;
    margin-bottom: 0.2rem;
  }

  @media (max-width: 650px) {
    height: 315px;
  }
`

const Content = styled.article`
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 60px;

  main {
    color: ${colors.greymid};
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 1.8;
    width: calc(100% - 140px);

    p {
      margin: 0;
      margin-bottom: 36px;
    }
  }

  @media (max-width: 850px) {
    margin-top: 40px;
    padding: 0;
    main {
      width: 100%;
    }
  }
  @media (max-width: 650px) {
    margin-top: 30px;
  }
`

const SocialContainer = styled.div`
  width: 96px;
  margin-top: -16px;
  margin-left: -8px;
  height: 80px;
  z-index: 99;

 /* //! these are hard-coded absolute values for responsive version -- beware! */
  @media (max-width: 850px) {
    transform: scale(0.6125);
    position: absolute;
    top: 231px;
    right: 37px;
  }
  @media (max-width: 650px) {
    transform: scale(0.5);
    position: absolute;
    top: 176px;
    /* //88 3 */
    /* right: ${props => props.padding}px; */
    right: 14px;
  }
`

const Post = ({ data }) => {
  const post = data.sanityPost
  const [resizeListener, sizes] = useResizeAware()

  const mid = useMedia({ maxWidth: 850 })
  const small = useMedia({ maxWidth: 650 })
  const padding = useMemo(() => {
    if (small) return 30
    if (mid) return 40
    return 60
  }, [mid, small])

  return (
    <Layout olive subsection="Blog">
      <SEO title={post.title} />
      <Container>
        {resizeListener}
        <BackButtonContainer
          type="button"
          onClick={() => navigate('/blog')}
          padding={padding}
        >
          <BackButton width={small ? 40 : 50} />
        </BackButtonContainer>
        <BackgroundImage
          Tag="section"
          fixed={post.mainImage.asset.fluid}
          backgroundColor="#C4C4C4"
        >
          {/* This is tricky -- setting width based on viewport (outside of css 🤔) */}
          <Header style={{ width: sizes.width - 2 * padding }}>
            <div>
              <h1>{post.title}</h1>
              <h2>{post.author.name}</h2>
            </div>
          </Header>
        </BackgroundImage>
        <Content>
          <SocialContainer padding={padding}>
            <Share large />
          </SocialContainer>
          <main>
            <BlockContent blocks={post._rawBody} />
          </main>
        </Content>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      author {
        name
      }
      mainImage {
        asset {
          fluid(maxWidth: 1160) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`
export default Post
