import React from 'react'

const SvgMail = ({ width = 40, ...props }) => (
  <svg width={width} fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20zm-29.682-9.643h19.364c1.662 0 3.014 1.299 3.014 2.895v13.496c0 1.596-1.352 2.895-3.014 2.895H10.318c-1.662 0-3.014-1.299-3.014-2.895V13.252c0-1.595 1.352-2.894 3.014-2.894zm0 16.73h19.364c.244 0 .458-.158.459-.338V15.036l-8.758 7.394a.999.999 0 01-.644.235H19.26a.998.998 0 01-.643-.235L9.86 15.036V26.75c0 .18.214.338.458.338zm1.164-14.175L20 20.128l8.518-7.216H11.482z"
      fill="#fff"
    />
  </svg>
)

export default SvgMail
